import React from 'react';
import { Image, Font, Page, Text, View, Document, StyleSheet, Link } from '@react-pdf/renderer';
import logo from "../../assets/images/logo_color.png"
import LocTecLogo from "../../assets/images/logo_color.png";
import AdsumLogo from "../../assets/images/adsumlogo.png";
// import DefaultCarImg from "../../../../../../src/@crema/components/PdfDownload/images/CarImg.jpg";
// import Sign from "../../../../../../src/@crema/components/PdfDownload/images/Sign.png";
import LocTecStamp from "../../assets/images/locationTec-stamp.png";

function PdfFile(props) {

    const styles = StyleSheet.create({
        page: {
            flexDirection: "column",
            width: "100%",
            height: "100vh",
            padding: "0",
        },
        Header: {
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#ff8c3d",
            width: "100%",
            padding: "10px 10px",
            position: "relative",
        },
        row: {
            flexDirection: "row",
            alignItems: "flex-start",
            width: "100%",
            justifyContent: "space-between",
        },
        brandDetails: {
            marginLeft: "2px",
            width: "40%",
        },
        brandTitle: {
            fontSize: 14,
            fontWeight: 600,
            color: "#ffffff",
            marginBottom: "4px",
        },
        description: {
            fontSize: 9,
            color: "#e0e0e0",
            padding: "1px 0px",
        },
        boldText1: {
            fontWeight: 800,
            fontSize: 10,
            color: "#ffffff",
        },
        contactInfo: {
            flexDirection: "column",
            marginLeft: "16px",
            width: "40%",
        },
        contactDetail: {
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "5px",
        },
        contactTitle: {
            width: "100px",
            color: "#ffffff",
            fontWeight: "bold",
            fontSize: 10,
        },
        logImg: {
            height: "20px",
            width: "85px",
            borderRadius: 20,
            overflow: 'hidden',
        },
        adsumlogImg: {
            height: "30px",
            width: "85px",
            // borderRadius: 20,
            overflow: 'hidden',
        },
        section2: {
            backgroundColor: "#ede4f6",
            width: "100%",
            // height: "10%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
        },
        MainFlexBox: {
            width: "100%",
        },
        flexMainBox: {
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            paddingLeft: "10px",
        },
        MainCarImg: {
            height: "100%",
            width: "200px",
            objectFit: "cover",
        },
        descriptions: {
            width: "25%",
            padding: "5px",
            fontSize: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
        },
        section3: {
            backgroundColor: "white",
            width: "100%",
            display: "flex",
            flexDirection: "row",
        },

        largeBox: {
            width: "90%",
            // paddingTop: 2,
            // paddingBottom: 2,
            // paddingLeft: 10,
            // paddingRight: 10,
            padding: "2px 10px",
            backgroundColor: "white",
        },
        mainBlock: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1px",
            fontSize: 7,
            color: "black",
            textAlign: "center",
        },
        subBlock: {
            width: "30%",
            textAlign: "left",
            marginRight: '5px'
        },
        CenteText: {
            display: "flex",
            flexDirection: "row",
            textAlign: "left",
            alignItems: "flex-end",
            fontSize: 11,
            padding: "2px",
            color: "gray",
        },
        greenText: {
            fontStyle: "italic",
            fontSize: 16,
            color: 'green'
        },
        redText: {
            fontStyle: "italic",
            fontSize: 16,
            color: 'red'
        },
        blueText: {
            fontStyle: "italic",
            fontSize: 16,
            color: 'blue'
        },
        BoldTitles: {
            fontSize: 14,
            color: "black",
            marginRight: "10px",
        },
        section4: {
            backgroundColor: "#ffffff",
            width: "100%",
            padding: "0px 5px",
            // display: "flex",
            flexDirection: "row",
            // gap: 10,
            alignItems: "flex-start",
        },
        caseBox: {
            padding: "0 10px",
            borderRadius: 10,
            border: "1px solid lightgray",
        },
        mainBox: {
            width: "49%",
            color: "black",
            fontSize: 10,
            margin: 3
        },
        subTitle: {
            backgroundColor: "#44008f",
            color: "white",
            padding: 5,
            fontSize: 9,
            textAlign: "center",
            borderRadius: 10
        },
        subBox: {
            // border: "1px solid red",
            borderWidth: 1,        // 1px width
            borderColor: 'gray',    // Red color
            borderStyle: 'solid',  // Solid border
            borderRadius: 10,
            margin: "1px 0px",
            overflow: "hidden",
        },
        subMenuData: {
            padding: "2px 0px",
            letterSpacing: 0.3,
            fontSize: 9,
        },
        subMenuTitle: {
            padding: "2px 0px",
            color: "gray",
            letterSpacing: 0.3,
            fontSize: 10,
        },
        subMenuTitleAdd: {
            color: "gray",
            width: '18%',
            paddingRight: 5,
            fontSize: 11,
        },
        subMenuDataAdd: {
            width: '80%',
            fontSize: 10,
        },
        shareDownload: {
            flexDirection: "column",
            alignItems: "center",
            padding: "5px",
        },
        page2: {
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f0f0f0",
            width: "100%",
            height: "100vh",
        },
        imageWrapper: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            // justifyContent: "center",
            // alignItems: "center",
            padding: 10,
        },
        Section5: {
            backgroundColor: "#ffffff",
            width: "100%",
            height: "20%",
            padding: "10px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
        },
        Box1: {
            width: "65%",
            padding: "10px",
            borderRadius: "10px",
            border: "1px solid lightgray",
        },
        normalText: {
            fontSize: 12,
            color: "black",
            margin: "5px 0px",
            fontWeight: 700
        },
        normalText5: {
            fontSize: 10,
            color: "gray",
            letterSpacing: 0.3,
            lineHeight: 1.4,
        },
        normalText6: {
            fontSize: 12,
            color: "black",
        },
        Box2: {
            width: "35%",
            height: "100%",
            padding: "10px",
        },
        signatureImage: {
            width: "100px",
            height: "100px",
            objectFit: "contain",
        },
        pageImage: {
            width: "50%",
            marginBottom: 10,
            // height: "100%",
            objectFit: "contain",
        },
        menuItem: {
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            padding: 5,
            borderBottomWidth: 0.5,
            borderBottomColor: 'gray',
            borderBottomStyle: 'solid',
        },
        menuItemAdd: {
            // width: '100%',
            backgroundColor: "#ede4f6",
            marginBottom: 5,
            padding: 10,
            flexDirection: "row",
            alignItems: 'flex-start',
        },
        label: {
            width: '50%',
            fontSize: 10,
            flexShrink: 0,
            marginRight: 5
        },
        value: {
            width: '49%',
            fontSize: 10,
            flexShrink: 0,
            color: 'gray',
        },
        GrayText: {
            color: "gray",
            fontSize: 9,
            padding: "2px 0px",
        },
        specialGrayText: {
            color: "black",
            fontSize: 9,
            padding: "2px 0px",
            marginBottom: "5px",
        },
        footer: {
            backgroundColor: "#44008f",
            padding: "10px",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "10px",
            position: "absolute",
            bottom: 0,
            width: "100%",
        },
        normalText7: {
            fontSize: 10,
            color: "white",
        },
        scratchedText: {
            color: "orange",
        },
        brokenText: {
            color: "red",
        },
        defaultText: {
            color: 'black'
        },
    });

    console.log(props?.data, 'hjjhjhj'); // Add this to see the contents of photos

    const splitDateAndTime = (createdAt) => {
        const date = new Date(createdAt);

        // Format the date part (e.g., "2024-12-26")
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

        // Format the time part (e.g., "05:58 AM")
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
        });

        return {
            date: formattedDate,
            time: formattedTime,
        };
    };

    // case completed time 
    const [ submittedDate, submittedTime ] = (props?.data?.timestamp.split(" , "));
      console.log("lll",submittedDate);

    // case submit time (creation)
    const { date: allocationDate, time: allocationTime } = splitDateAndTime(props?.data?.createdat);

    return (
        <Document>
            <Page size={{ width: 595.276, height: 1080 }} style={styles.page}>
                <View style={styles.Header}>
                    <View style={styles.row}>
                        {/* <Image src={LogoImg} style={styles.logo} /> */}
                        <View style={styles.brandDetails}>
                            {/* <Text style={styles.brandTitle}>Smart AI Inspection</Text> */}
                            <Image src={AdsumLogo} style={styles.adsumlogImg} />
                            <Text style={styles.description}>
                                Unit No-11A, Office No. 411, 4th Floor
                            </Text>
                            <Text style={styles.description}>Amsri Square Next to Apollo Hospital</Text>
                            <Text style={styles.description}>Hyderabad-500003, T S</Text>
                        </View>
                        <View style={styles.contactInfo}>
                            <View style={styles.contactDetail}>
                                <Text style={styles.contactTitle}>Phone Number:</Text>
                                <Text style={styles.boldText1}>022-69719073</Text>
                            </View>
                            <View style={styles.contactDetail}>
                                <Text style={styles.contactTitle}>Email:</Text>
                                <Text style={styles.boldText1}>info@adsum.co.in</Text>
                            </View>
                            <View style={styles.contactDetail}>
                                <Image src={LocTecLogo} style={styles.logImg} />
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.section2}>
                    <View style={styles.MainFlexBox}>
                        <View style={styles.flexMainBox}>
                            <View style={styles.descriptions}>
                                <Text style={styles.subMenuTitle}>Client</Text>
                                <Text style={styles.subMenuData}>
                                    {props?.data?.bank || 'NA'}
                                </Text>
                            </View>
                            <View style={styles.descriptions}>
                                <Text style={styles.subMenuTitle}>Case Id</Text>
                                <Text style={styles.subMenuData}>{props?.data.applicationId || 'NA'}</Text>
                            </View>
                            <View style={styles.descriptions}>
                                <Text style={styles.subMenuTitle}>Verification Type</Text>
                                <Text style={styles.subMenuData}>{props?.data?.fi || 'NA'}</Text>
                            </View>
                            <View style={styles.descriptions}>
                                <Text style={styles.subMenuTitle}>Product</Text>
                                <Text style={styles.subMenuData}>{props?.data?.productName || 'NA'}</Text>
                            </View>
                        </View>
                        <View style={styles.flexMainBox}>
                            <View style={styles.descriptions}>
                                <Text style={styles.subMenuTitle}>Client Branch</Text>
                                <Text style={styles.subMenuData}>{props?.data?.branchName || 'NA'}</Text>
                            </View>
                            <View style={styles.descriptions}>
                                <Text style={styles.subMenuTitle}>Co-Applicant Name</Text>
                                <Text style={styles.subMenuData}>{props?.data?.coApplicantName || 'NA'}</Text>
                            </View>
                            <View style={styles.descriptions}>
                                <Text style={styles.subMenuTitle}>Mobile</Text>
                                <Text style={styles.subMenuData}>{props?.data?.mobileNumber || 'NA'}</Text>
                            </View>
                            <View style={styles.descriptions}>
                                <Text style={props?.data?.qCpvStatus === 'Positive' ? styles.greenText :
                                    props?.data?.qCpvStatus === 'Negative' ? styles.redText :
                                        styles.normalText}
                                >
                                    Status : {props?.data?.qCpvStatus || 'NA'}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.section3}>
                    <View style={styles.largeBox}>
                        <View style={styles.CenteText}>
                            <Text style={styles.BoldTitles}>{props?.data?.customerName || 'NA'}</Text>

                        </View>
                        <View style={styles.mainBlock}>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Company Name</Text>
                                <Text style={styles.specialGrayText}>{props?.data?.qCoOffice || 'NA'}</Text>
                            </View>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Occupation</Text>
                                <Text style={styles.specialGrayText}>{props?.data?.qOccupation || 'NA'}</Text>
                            </View>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Verifier Emp Name</Text>
                                <Text style={styles.specialGrayText}>
                                    {props?.data?.agentName || 'NA'}
                                </Text>
                            </View>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Back Office Emp Name</Text>
                                <Text style={styles.specialGrayText}>
                                    {/* Test Back Office Emp Id */}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.mainBlock}>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>OCL Range</Text>
                                <Text style={styles.specialGrayText}>
                                    {/* Test OCL Range */}NA
                                </Text>
                            </View>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Sub Status</Text>
                                <Text style={styles.specialGrayText}>{props?.data?.status || 'NA'}</Text>
                            </View>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Time of Allocation</Text>
                                <Text style={styles.specialGrayText}>{allocationTime}</Text>
                            </View>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Date of Allocation</Text>
                                <Text style={styles.specialGrayText}>{allocationDate}</Text>
                            </View>
                        </View>
                        <View style={styles.mainBlock}>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Date of Report</Text>
                                <Text style={styles.specialGrayText}>{submittedDate}</Text>
                            </View>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Time of Report</Text>
                                <Text style={styles.specialGrayText}>
                                    {submittedTime}
                                </Text>
                            </View>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>Submitted Loc</Text>
                                <Text style={styles.specialGrayText}>
                                    {props?.data?.markerLatitude} {props?.data?.markerLongitude}
                                </Text>
                            </View>
                            <View style={styles.subBlock}>
                                <Text style={styles.GrayText}>TAT Met</Text>
                                <Text style={styles.specialGrayText}>{props?.data?.tattime || 'NA'}</Text>
                            </View>
                        </View>
                    </View>
                </View>

                {/* Address */}
                <View style={styles.menuItemAdd}>
                    <Text style={styles.subMenuTitleAdd}>{props.data.fi === 'BV' ? 'Business Address' : 'Residence Address'}</Text>
                    <Text style={styles.subMenuDataAdd}>{props.data.fi === 'BV' ? props?.data?.officeAddress : props?.data?.residentAddresss}</Text>
                </View>

                <Text style={styles.subTitle}>OTHER INFORMATION</Text>
                {props?.data?.fi === 'BV' ?
                    <View style={styles.section4}>
                        <View style={styles.mainBox}>
                            <View style={styles.subBox}>
                                {/* <Text style={styles.subTitle}>BV</Text> */}
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Address Confirmed</Text>
                                    <Text style={styles.value}>{props?.data?.qAddressConfirmed || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Name of Co. / Officer</Text>
                                    <Text style={styles.value}>{props?.data?.qCoOffice || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Person Met During the Visit</Text>
                                    <Text style={styles.value}>{props?.data?.qPersonMet || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Designation of Person Met</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qPersonMetDesignation || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Exact Designation of Applicant</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qPersonDesignation}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Working Since</Text>
                                    <Text style={styles.value}>{props?.data?.qWorkingSince || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Is Business premesis Owned or Rented</Text>
                                    <Text style={styles.value}>{props?.data?.qOfficeOwnership || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Nature of Business / Industry</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qNature || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Name Plate / Business Board Type</Text>
                                    <Text style={styles.value}>{props?.data?.qVerifiedFrom || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Name Mentioned on SignBoard</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qSignBoardText || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>No of Employees Sighted</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qNumberEmployees || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Number of Years in Service</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qStability || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Carpet Area / Sq.Ft.</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qArea || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Type Of Roof Of The Resi / Offi</Text>
                                    <Text style={styles.value}>{props?.data?.qTypeOfResidence || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Type of Business Activity</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qBusinessActivity || 'NA'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.mainBox}>
                            <View style={styles.subBox}>
                                {/* <Text style={styles.subTitle}>OTHER INFORMATION</Text> */}
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Stocks</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qStocks || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Proof Of Office Seen</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qOfficeSeenText || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Nature Of Business Proofs</Text>
                                    <Text style={styles.value}>{props?.data?.qBusinessProof || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Locality / Surrounding Area</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qLocalityOfResidence || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Landmark</Text>
                                    <Text style={styles.value}>
                                    {props?.data?.qLandmark || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Distance From Branch (in KM)</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qBranchDistance || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Accessibility/Ease of Locating Address/Condition of Approach Road</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qEaseOfLocate || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>TPC Name 1 </Text>
                                    <Text style={styles.value}>{props?.data?.qTpc1 || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>TPC Name 2</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qTpc2 || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Neighbour 1 Status </Text>
                                    <Text style={styles.value}>{props?.data?.qTpc1Check || 'NA'}</Text>
                                </View>

                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Neighbour 2 Status</Text>
                                    <Text style={styles.value}>{props?.data?.qTpc2Check || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Occupation / Source of Income</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qOccupation || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Approx Income</Text>
                                    <Text style={styles.value}>{props?.data?.qApproxIncome || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Main Business of Company</Text>
                                    <Text style={styles.value}>{props?.data?.qBusinessProof || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Note</Text>
                                    <Text style={styles.value}>NA</Text>
                                </View>
                            </View>

                        </View>
                    </View>
                    :
                    <View style={styles.section4}>
                        <View style={styles.mainBox}>
                            <View style={styles.subBox}>
                                {/* <Text style={styles.subTitle}>RV </Text> */}
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Address Confirmed</Text>
                                    <Text style={styles.value}>{props?.data?.qAddressConfirmed || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Stay Confirmed</Text>
                                    <Text style={styles.value}>{props?.data?.qStayConfirmed || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Person Met</Text>
                                    <Text style={styles.value}>{props?.data?.qPersonMet || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Relation With Applicant</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qPersonRelationship || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Ownership Residence</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qResidenceOwnership || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Rent</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qRent || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Age</Text>
                                    <Text style={styles.value}>{props?.data?.qAge || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Education</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qEducation || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Total Family Members</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qFamilyMembersCount || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Total Earning Members</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qEarningMembersCount || 'NA'}
                                    </Text>
                                </View> 
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Spouse Working</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qSpouseWorking || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Accessibility / Ease Of Locating Adddress / Condition Of Approach Road</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qEaseOfLocate || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Landmark</Text>
                                    <Text style={styles.value}>{props?.data?.qLandmark || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Locality of Residence</Text>
                                    <Text style={styles.value}>{props?.data?.qLocalityOfResidence || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Area Of Residence</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qAreaofResidence || 'NA'}
                                    </Text>
                                </View>   
                            </View>

                        </View>
                        <View style={styles.mainBox}>
                            <View style={styles.subBox}>
                                {/* <Text style={styles.subTitle}>OTHER INFORMATION</Text> */}
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Stability</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qStability || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Interior / Internal Appearance / Standard Of Living</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qCommentOnInterior || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Assest Seen At Resi / Offi</Text>
                                    <Text style={styles.value}>{props?.data?.qAssetSeenAtResidence || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Comment on Interior</Text>
                                    <Text style={styles.value}>{props?.data?.qCommentOnInterior || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Type Of The Resi / Off</Text>
                                    <Text style={styles.value}>{props?.data?.qTypeOfResidence || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Carpet Area / Sq.Ft.</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qArea || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Area Of House</Text>
                                    <Text style={styles.value}>{props?.data?.qArea || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Entry In The Complex / Building Allowed</Text>
                                    <Text style={styles.value}>{props?.data?.qVerifiedFrom || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>TPC Name 1</Text>
                                    <Text style={styles.value}>{props?.data?.qTpc1 || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>TPC Name 2</Text>
                                    <Text style={styles.value}>{props?.data?.qTpc2 || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Adddress Of Neighbour 1</Text>
                                    <Text style={styles.value}>{props?.data?.qNeighbour1 || 'NA'}</Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Adddress Of Neighbour 2</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qNeighbour2 || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>TPC Status 1</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qTpc1Check || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>TPC Status 2</Text>
                                    <Text style={styles.value}>
                                        {props?.data?.qTpc2Check || 'NA'}
                                    </Text>
                                </View>
                                <View style={styles.menuItem}>
                                    <Text style={styles.label}>Note</Text>
                                    <Text style={styles.value}>NA</Text>
                                </View>
                            </View>

                        </View>

                    </View>
                }

                <View style={styles.menuItemAdd}>
                    <Text style={styles.subMenuTitleAdd}>Internal Backcheck</Text>
                    <Text style={styles.subMenuDataAdd}>{props?.data?.internalBackcheck || 'NA'}</Text>
                </View>

                <View style={styles.Section5}>

                    {/* <View style={styles.Box1}>
                        <Text style={styles.normalText}>Comments:</Text>
                        <Text style={styles.normalText5}>
                            Agent Remarks : {props?.data?.qAgentRemarks || 'NA'}
                        </Text>
                        <Text style={styles.normalText5}>
                            Reject Reason : {props?.data?.qRejectReason || 'NA'}
                        </Text>
                    </View> */}

                    <View style={styles.Box1}>
                        <Text style={styles.normalText}>Case Summary:</Text>
                        {props?.data?.fi === 'BV' ?
                            <>
                                {props.data.qCpvStatus === "Positive" ?
                                    (
                                        <>
                                            <Text style={styles.normalText5}>
                                                Visit Date and Time:  {(props.data.timestamp)} || Person Met: {props.data.qPersonMet}, Person Met Designation: {props.data.qPersonMetDesignation}, Name of Co. /Officer: {props.data.qCoOffice}
                                                Address Confirmation: {props.data.qAddressConfirmed}, Applicant Designation: {props.data.qPersonDesignation}, Business Adddress: {props.data.officeAddress}, Nature of Business: {props.data.qNature}
                                                Office Ownership: {props.data.qOfficeOwnership}, Working Since: {props.data.qWorkingSince}, Number of Employees: {props.data.qNumberEmployees}, Stocks: {props.data.qStocks},
                                                Business Activity: {props.data.qBusinessActivity}, Name Mentioned on SignBoard: {props.data.qSignBoardText},
                                                TPC 1 Name: {props.data.qTpc1},
                                                TPC 2 Name: {props.data.qTpc2}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude: {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
                                            </Text>
                                        </>
                                    ) :
                                    (<Text style={styles.normalText5}>
                                        Visit Date and Time:  {(props.data.timestamp)}, Agent Remarks: {props.data.qAgentRemarks}, Reject Reason: {props?.data?.qRejectReason}, Latitude: {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
                                    </Text>)
                                }
                                <Text style={props?.data?.qCpvStatus === 'Positive' ? styles.greenText :
                                    props?.data?.qCpvStatus === 'Negative' ? styles.redText :
                                        styles.normalText}
                                >
                                    Status : {props?.data?.qCpvStatus || 'NA'}
                                </Text>
                            </>
                            :
                            <>
                                {props.data.qCpvStatus === "Positive" ?
                                    (
                                        <>
                                            <Text style={styles.normalText5}>
                                                Visit Date and Time:  {(props.data.timestamp)} , Address Confirmation: {props.data.qAddressConfirmed}, Name of Person Met: {props.data.qPersonMet}, Relation With Applicant: {props.data.qPersonRelationship},
                                                Ownership Residence: {props.data.qResidenceOwnership}, Age: {props.data.qAge} ,  Education: {props.data.qEducation} , TFM: {props.data.qFamilyMembersCount}, Earning: {props.data.qEarningMembersCount},
                                                Rent Per Month: {props.data.qRent}, Area (In Sqft): {props.data.qArea}, Landmark: {props.data.qLandmark}, Type of Residence: {props.data.qTypeOfResidence},  Locality of Residence: {props.data.qLocalityOfResidence},
                                                Residence Construction: {props.data.qResidenceConstruction}, Name of the Person: {props.data.qPersonNameVerification},
                                                Applicant Details Confirmed: {props.data.qApplicantDetailsConfirmed}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
                                            </Text>
                                        </>
                                    ) :
                                    (<Text style={styles.normalText5}>
                                        Visit Date and Time:  {(props.data.timestamp)} , Landmark: {props.data.qLandmark}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Reject Reason: {props?.data?.qRejectReason}, Latitude : {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
                                    </Text>)
                                }
                                <Text style={props?.data?.qCpvStatus === 'Positive' ? styles.greenText :
                                    props?.data?.qCpvStatus === 'Negative' ? styles.redText :
                                        styles.normalText}
                                >
                                    Status : {props?.data?.qCpvStatus || 'NA'}
                                </Text>
                            </>
                        }
                    </View>

                    <View style={styles.Box2}>
                        <View style={styles.shareDownload}>
                            <Text style={styles.normalText6} >Authorized Signature:</Text>
                        </View>
                        <View style={styles.shareDownload}>
                            <Image src={LocTecStamp} style={styles.signatureImage} />
                        </View>
                    </View>
                </View>

                <View style={styles.footer}>
                    <Text style={styles.normalText7}>Executive Name: {props?.data?.executiveName || 'NA'}</Text>
                    <Text style={styles.normalText7}>
                        Report Generated At: {submittedDate} : {submittedTime}
                    </Text>
                    <Text style={styles.normalText7}>QC Done by: {props?.data?.agentName || 'NA'}</Text>
                </View>

            </Page>

            <Page size={{ width: 595.276, height: 1080 }} style={styles.page2}>
                <View style={styles.Header}>
                    <View style={styles.row}>
                        {/* <Image src={LogoImg} style={styles.logo} /> */}
                        <View style={styles.brandDetails}>
                            {/* <Text style={styles.brandTitle}>Smart AI Inspection</Text> */}
                            <Image src={AdsumLogo} style={styles.adsumlogImg} />
                            <Text style={styles.description}>
                                Unit No-11A, Office No. 411, 4th Floor
                            </Text>
                            <Text style={styles.description}>Amsri Square Next to Apollo Hospital</Text>
                            <Text style={styles.description}>Hyderabad-500003, T S</Text>
                        </View>
                        <View style={styles.contactInfo}>
                            <View style={styles.contactDetail}>
                                <Text style={styles.contactTitle}>Phone Number:</Text>
                                <Text style={styles.boldText1}>022-69719073</Text>
                            </View>
                            <View style={styles.contactDetail}>
                                <Text style={styles.contactTitle}>Email:</Text>
                                <Text style={styles.boldText1}>info@adsum.co.in</Text>
                            </View>
                            <View style={styles.contactDetail}>
                                <Image src={LocTecLogo} style={styles.logImg} />
                            </View>
                        </View>
                    </View>
                </View>

                {/* {
                    props?.data?.photos && props?.data?.photos?.length > 0 ? (
                        <View style={styles.imageWrapper} >
                            {props?.data?.photos.map((image, index) => {
                                console.log("photooo", image.Photo);
                                return(
                                <Link src={image.Photo} key={index}>
                                    <Image src={{ uri: image.Photo, method: 'GET', headers: { "Cache-Control": "no-cache" } }} style={styles.pageImage} />
                                </Link>)}
                            )}
                        </View>
                    ) : (
                        <Text>No photos available</Text>
                    )

                } */}
                {props?.data?.photos.length > 0 ?
                    <>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            {props.data.photos.slice(0, 2).map((rowData, index) => (
                                <Link src={rowData.Photo}>
                                    <Image src={{ uri: rowData.Photo, method: 'GET', headers: { "Cache-Control": "no-cache" } }} style={{ width: 300, height: 250, padding: 10 }} />
                                </Link>
                            ))}
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            {props.data.photos.slice(2, 4).map((rowData, index) => (
                                <Link src={rowData?.Photo}>
                                    <Image src={{ uri: rowData.Photo, method: 'GET', headers: { "Cache-Control": "no-cache" } }} style={{ width: 300, height: 250, padding: 10 }} />
                                </Link>
                            ))}
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            {props.data.photos.slice(4, 6).map((rowData, index) => (
                                <Link src={rowData?.Photo}>
                                    <Image src={{ uri: rowData.Photo, method: 'GET', headers: { "Cache-Control": "no-cache" } }} style={{ width: 300, height: 250, padding: 10 }} />
                                </Link>
                            ))}
                        </View>

                    </> :
                    <Text>No photos available</Text>
                }


                {/* <View>
            <Image src={Stamp2} style={styles.signatureImage} />
            <Text style={styles.normalText6} >Authorized Signature:</Text>
          </View> */}
                {/* <ImageGallery images={user?.images} /> */}

                <View style={styles.footer}>
                    <Text style={styles.normalText7}>Executive Name: {props?.data?.executiveName || 'NA'}</Text>
                    <Text style={styles.normalText7}>
                        Report Generated At: {submittedDate} : {submittedTime}
                    </Text>
                    <Text style={styles.normalText7}>QC Done by: {props?.data?.agentName || 'NA'}</Text>
                </View>
            </Page>
        </Document>
    );
}

export default PdfFile;